import * as React from 'react';
import { Menu, Segment, MenuItemProps, Responsive, Sidebar, Container, Icon } from 'semantic-ui-react';
import '../../App.css';
import { navigate } from 'gatsby';
import { NavigateOptions } from '@reach/router';

interface NavbarProps {
  activeItem?: 'home' | 'about' | 'blog' | 'books';
}

interface NavbarState {
  sidebarOpened: boolean;
}

export default class MobileNavbar extends React.Component<NavbarProps, NavbarState> {
  state = { sidebarOpened: false };

  handleSidebarHide = () => this.setState({ sidebarOpened: false });
  handleToggle = () => this.setState({ sidebarOpened: true });

  handleItemClick = (_e: React.MouseEvent<HTMLAnchorElement>, data: MenuItemProps) => {
    const options: NavigateOptions<{}> = {};
    options.replace = true;
    if (data.name === 'home') {
      navigate('/', options);
    } else {
      navigate(`/${data.name}`, options);
    }
  };

  renderSidebar = () => {
    const { activeItem } = this.props;
    const { sidebarOpened } = this.state;
    return (
      <Sidebar as={Menu} animation="push" inverted onHide={this.handleSidebarHide} vertical visible={sidebarOpened}>
        <Menu.Item to="/" name="home" active={activeItem === 'home'} onClick={this.handleItemClick} />
        <Menu.Item to="/about" name="about" active={activeItem === 'about'} onClick={this.handleItemClick} />
        <Menu.Item to="blog" name="blog" active={activeItem === 'blog'} onClick={this.handleItemClick} />
        <Menu.Item to="books" name="books" active={activeItem === 'books'} onClick={this.handleItemClick} />
        <Menu.Item href={'/resume.pdf'} to="/resume.pdf" name="resume" target="_blank" />
      </Sidebar>
    );
  };

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;
    return (
      <Responsive
        as={Sidebar.Pushable}
        minWidth={Responsive.onlyMobile.minWidth}
        maxWidth={Responsive.onlyComputer.minWidth}
      >
        {this.renderSidebar()}
        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment inverted textAlign="center" vertical>
            <Container>
              <Menu inverted pointing secondary size="large">
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>
          {children}
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}
