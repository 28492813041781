import * as React from 'react';
import { Menu, Segment, MenuItemProps, Grid, Responsive } from 'semantic-ui-react';
import '../../App.css';
import { navigate } from 'gatsby';
import { NavigateOptions } from '@reach/router';

interface NavbarProps {
  activeItem?: 'home' | 'about' | 'blog' | 'books';
}

export default class Navbar extends React.Component<NavbarProps, {}> {
  handleItemClick = (_e: React.MouseEvent<HTMLAnchorElement>, data: MenuItemProps) => {
    const options: NavigateOptions<{}> = {};
    options.replace = true;
    if (data.name === 'home') {
      navigate('/', options);
    } else {
      navigate(`/${data.name}/`, options);
    }
  };

  renderHeader = () => {
    const { activeItem } = this.props;
    return (
      <Menu inverted pointing secondary stackable>
        <Menu.Item to="/" name="home" link={true} active={activeItem === 'home'} onClick={this.handleItemClick} />
        <Menu.Menu position="right">
          <Menu.Item to="about" name="about" active={activeItem === 'about'} onClick={this.handleItemClick} />
          <Menu.Item to="blog" name="blog" active={activeItem === 'blog'} onClick={this.handleItemClick} />
          <Menu.Item to="books" name="books" active={activeItem === 'books'} onClick={this.handleItemClick} />
          <Menu.Item href={'/resume.pdf'} to="/resume.pdf" name="resume" target="_blank" />
        </Menu.Menu>
      </Menu>
    );
  };

  render() {
    const { children } = this.props;
    return (
      <Responsive minWidth={Responsive.onlyComputer.minWidth} maxWidth={Responsive.onlyWidescreen.maxWidth}>
        <Segment inverted textAlign="center" vertical>
          <Grid centered={true}>
            <Grid.Column mobile={12} tablet={10} computer={8} largeScreen={8} widescreen={8}>
              {this.renderHeader()}
            </Grid.Column>
          </Grid>
        </Segment>
        {children}
      </Responsive>
    );
  }
}
