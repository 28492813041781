import * as React from 'react';
import NavbarContainer from '../navbar/navbarContainer';
import { Helmet } from 'react-helmet';

interface Props {
  children: any;
  activeItem?: 'home' | 'about' | 'blog' | 'books' | 'blog-post';
  blogTitle?: string;
  blogDescription?: string;
  blogPath?: string;
}

class PageWrapper extends React.Component<Props, any> {
  renderMetaDescription = () => {
    const { activeItem, blogTitle, blogDescription } = this.props;
    if (!activeItem) {
      return null;
    }
    let content: string;
    switch (activeItem) {
      case 'home':
        content = "Kevin Xu's Home Page";
        break;
      case 'about':
        content =
          'Kevin Xu is a Senior Software Engineer living in the Bay Area. Enjoys writing blog posts on various topics.';
        break;
      case 'blog':
        content =
          "Kevin Xu's Blog page. Discussing topics related to software engineering, personal finance, productivity and more.";
        break;
      case 'books':
        content = "List of books I've read or currently are reading that I recommend.";
        break;
      case 'blog-post':
        content = `${blogTitle} ${blogDescription}`;
        break;
    }
    return <meta name="description" content={content} />;
  };

  getUrl = () => {
    const { activeItem, blogPath } = this.props;
    if (activeItem === 'home') {
      return 'https://www.kevinjxu.com';
    } else if (activeItem === 'blog-post') {
      return `https://www.kevinjxu.com/${blogPath}`;
    }
    return `https://www.kevinjxu.com/${activeItem}/`;
  };

  renderHelmet = () => {
    const { activeItem, blogTitle } = this.props;
    if (!activeItem) {
      return null;
    }

    let title: string;
    if (activeItem === 'blog-post') {
      title = `Blog post: ${blogTitle}`;
    } else {
      title = activeItem.charAt(0).toUpperCase() + activeItem.slice(1);
    }

    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link rel="canonical" href={this.getUrl()} />
        {this.renderMetaDescription()}
        <html lang="en" />
      </Helmet>
    );
  };

  render() {
    const { children, activeItem } = this.props;
    return (
      <React.Fragment>
        {this.renderHelmet()}
        <NavbarContainer activeItem={activeItem === 'blog-post' ? 'blog' : activeItem}>
          <div className={'page-children-container'}>{children}</div>
        </NavbarContainer>
      </React.Fragment>
    );
  }
}

export default PageWrapper;
