import Navbar from './navbar';
import MobileNavbar from './mobileNavbar';
import * as React from 'react';

interface Props {
  children: any;
  activeItem?: 'home' | 'about' | 'blog' | 'books';
}

class NavbarContainer extends React.Component<Props, any> {
  render() {
    const { children, activeItem } = this.props;
    return (
      <div>
        <Navbar activeItem={activeItem}>{children}</Navbar>
        <MobileNavbar activeItem={activeItem}>{children}</MobileNavbar>
      </div>
    );
  }
}

export default NavbarContainer;
